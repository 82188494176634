define("ember-buffered-proxy/mixin", ["exports", "ember-buffered-proxy/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var notifyPropertyChange = Ember.notifyPropertyChange,
      meta = Ember.meta; // eslint-disable-line ember/new-module-imports

  var hasOwnProp = Object.prototype.hasOwnProperty;

  var _default = Ember.Mixin.create({
    buffer: null,
    hasBufferedChanges: false,
    hasChanges: Ember.computed.readOnly('hasBufferedChanges'),
    applyChanges: (0, _helpers.aliasMethod)('applyBufferedChanges'),
    discardChanges: (0, _helpers.aliasMethod)('discardBufferedChanges'),
    init: function init() {
      this.initializeBuffer();
      Ember.set(this, 'hasBufferedChanges', false);

      this._super.apply(this, arguments);
    },
    initializeBuffer: function initializeBuffer(onlyTheseKeys) {
      var _this = this;

      if (Ember.isArray(onlyTheseKeys) && !(0, _helpers.empty)(onlyTheseKeys)) {
        onlyTheseKeys.forEach(function (key) {
          return delete _this.buffer[key];
        });
      } else {
        Ember.set(this, 'buffer', Object.create(null));
      }
    },
    unknownProperty: function unknownProperty(key) {
      var buffer = Ember.get(this, 'buffer');
      return hasOwnProp.call(buffer, key) ? buffer[key] : this._super(key);
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      var m = meta(this);

      if (m.proto === this || m.isInitializing && m.isInitializing()) {
        // if marked as prototype or object is initializing then just
        // defineProperty rather than delegate
        Ember.defineProperty(this, key, null, value);
        return value;
      }

      var _Ember$getProperties = Ember.getProperties(this, ['buffer', 'content']),
          buffer = _Ember$getProperties.buffer,
          content = _Ember$getProperties.content;

      var current;
      var previous;

      if (content != null) {
        current = Ember.get(content, key);
      }

      previous = hasOwnProp.call(buffer, key) ? buffer[key] : current;

      if (previous === value) {
        return;
      }

      if (current === value) {
        delete buffer[key];

        if ((0, _helpers.empty)(buffer)) {
          Ember.set(this, 'hasBufferedChanges', false);
        }
      } else {
        buffer[key] = value;
        Ember.set(this, 'hasBufferedChanges', true);
      }

      notifyPropertyChange(content, key);
      return value;
    },
    applyBufferedChanges: function applyBufferedChanges(onlyTheseKeys) {
      var _Ember$getProperties2 = Ember.getProperties(this, ['buffer', 'content']),
          buffer = _Ember$getProperties2.buffer,
          content = _Ember$getProperties2.content;

      Object.keys(buffer).forEach(function (key) {
        if (Ember.isArray(onlyTheseKeys) && onlyTheseKeys.indexOf(key) === -1) {
          return;
        }

        Ember.set(content, key, buffer[key]);
      });
      this.initializeBuffer(onlyTheseKeys);

      if ((0, _helpers.empty)(Ember.get(this, 'buffer'))) {
        Ember.set(this, 'hasBufferedChanges', false);
      }
    },
    discardBufferedChanges: function discardBufferedChanges(onlyTheseKeys) {
      var _Ember$getProperties3 = Ember.getProperties(this, ['buffer', 'content']),
          buffer = _Ember$getProperties3.buffer,
          content = _Ember$getProperties3.content;

      this.initializeBuffer(onlyTheseKeys);
      Object.keys(buffer).forEach(function (key) {
        if (Ember.isArray(onlyTheseKeys) && onlyTheseKeys.indexOf(key) === -1) {
          return;
        }

        notifyPropertyChange(content, key);
      });

      if ((0, _helpers.empty)(Ember.get(this, 'buffer'))) {
        Ember.set(this, 'hasBufferedChanges', false);
      }
    },

    /*
     * Determines if a given key has changed else returns false. Allows individual key lookups where
     * as hasBufferedChanged only looks at the whole buffer.
     */
    hasChanged: function hasChanged(key) {
      var _Ember$getProperties4 = Ember.getProperties(this, ['buffer', 'content']),
          buffer = _Ember$getProperties4.buffer,
          content = _Ember$getProperties4.content;

      if (typeof key !== 'string' || typeof Ember.get(buffer, key) === 'undefined') {
        return false;
      }

      if (Ember.get(buffer, key) !== Ember.get(content, key)) {
        return true;
      }

      return false;
    }
  });

  _exports.default = _default;
});